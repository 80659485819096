import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/templates/PageLocal.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "sem-accomplishments"
    }}>{`SEM Accomplishments`}</h1>
    <h2 {...{
      "id": "sem-2022-results"
    }}>{`SEM 2022 RESULTS`}</h2>
    <p>{`We achieved new milestones in 2022`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "2022results",
          "title": "2022results",
          "src": "/static/e3353b72cde4f1c16aff8bcc42d04e09/c1b63/Picture1.png",
          "srcSet": ["/static/e3353b72cde4f1c16aff8bcc42d04e09/5a46d/Picture1.png 300w", "/static/e3353b72cde4f1c16aff8bcc42d04e09/0a47e/Picture1.png 600w", "/static/e3353b72cde4f1c16aff8bcc42d04e09/c1b63/Picture1.png 1200w", "/static/e3353b72cde4f1c16aff8bcc42d04e09/d61c2/Picture1.png 1800w", "/static/e3353b72cde4f1c16aff8bcc42d04e09/97a96/Picture1.png 2400w", "/static/e3353b72cde4f1c16aff8bcc42d04e09/49c09/Picture1.png 4152w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "achievements-sem-teams-2022"
    }}>{`Achievements SEM Teams 2022`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "semteams2022",
          "title": "semteams2022",
          "src": "/static/498cd957f76eeb7de2a64d8f5fae3c42/c1b63/semteams2022.png",
          "srcSet": ["/static/498cd957f76eeb7de2a64d8f5fae3c42/5a46d/semteams2022.png 300w", "/static/498cd957f76eeb7de2a64d8f5fae3c42/0a47e/semteams2022.png 600w", "/static/498cd957f76eeb7de2a64d8f5fae3c42/c1b63/semteams2022.png 1200w", "/static/498cd957f76eeb7de2a64d8f5fae3c42/d61c2/semteams2022.png 1800w", "/static/498cd957f76eeb7de2a64d8f5fae3c42/97a96/semteams2022.png 2400w", "/static/498cd957f76eeb7de2a64d8f5fae3c42/b3bf0/semteams2022.png 4363w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      